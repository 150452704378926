import React from "react";
import AccordionUsage from "../components/theories";

export default function Theories() {
  return (
    <div>
      <AccordionUsage/>
        
    </div>
  );
}
