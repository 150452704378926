const booksData = [
  {
    id: 1,
    title: "Хөгжлийн Сэтгэл Судлал",
    author: "М. Дэлгэржав",
    year: "2023",
    imageUrl:
      "https://sinpim130259-dev.s3.ap-southeast-1.amazonaws.com/public/product-image/1GifZHoJsJtXyeYcUAE1.jpg",
    details: "Хөгжлийн Сэтгэл Судлал",
  },
];

export default booksData;
